<script>
  import HotNewCarGadgetBFCM from './HotNewCarGadgetBFCM.svelte'

  import Tracker from '@/util/tracker.js'

  const checkoutLink = 'https://shop.fixdapp.com/lp/bfcm-sensor-67'

  const onClick = async (event) => {
    try {
      Tracker.track('trusted tech reviews link clicked', {
        page_name: 'TTRHotNewCarGadget',
        clean_url: `${window.location.origin.toString()}${window.location.pathname.toString()}`,
      })
    } catch (error) {
      throw new Error('trusted tech reviews link click error')
    } finally {
      window.location.href = event.target.href
    }
  }
</script>

<main>
  <HotNewCarGadgetBFCM {onClick} {checkoutLink} />
</main>
