// The import name must be the same as the file name to work.
// i.e. Sensor67 from '@/pages/Sensor67.svelte' works; NOT SensorTest from '@/pages/Sensor67.svelte

import WebpackerSvelte from 'webpacker-svelte'
import TTRHotNewCarGadget from '@/pages/scorpio/ttr/TTRHotNewCarGadget.svelte'
import HotNewCarGadgetKate from '@/pages/scorpio/ttr/HotNewCarGadgetKate.svelte'
import HotNewCarGadgetControl from '@/pages/scorpio/ttr/HotNewCarGadgetControl.svelte'
import HotNewCarGadgetBFCM from '@/pages/scorpio/ttr/HotNewCarGadgetBFCM.svelte'
import HotNewCarGadgetLandingPage from '@/pages/scorpio/ttr/HotNewCarGadgetLandingPage.svelte'

WebpackerSvelte.setup({
  TTRHotNewCarGadget,
  HotNewCarGadgetKate,
  HotNewCarGadgetControl,
  HotNewCarGadgetBFCM,
  HotNewCarGadgetLandingPage,
})
